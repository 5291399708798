@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background-color: #000000;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="text"], input[type="select"] {
  outline: none !important;
}

.main-container {
  max-width: 840px;
}

.fade-in {
  opacity: 0;
}

.fade-in.is-visible {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}
